























import { computed, defineComponent } from '@vue/composition-api';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import fcCalendar from '@/components/FcCalendar.vue';

export default defineComponent({
  name: 'Schedules',
  components: {
    FcRoleLoading,
    fcCalendar,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('schedules'));
    return {
      pageTitle: 'スケジュール',
      myRoleSettings,
    };
  },
});
