










import { defineComponent, ref, onMounted, computed } from '@vue/composition-api';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import { DateSelectArg, EventSourceFuncArg, EventContentArg } from '@fullcalendar/core';
import listPlugin from '@fullcalendar/list'; // 予定リストを表示する
import allLocales from '@fullcalendar/core/locales-all'; //日本語化用
import interactionPlugin from '@fullcalendar/interaction'; // selectメソッド用
import { getScheduleItems } from '@/composition/schedule';
import myAttributes from '@/composition/myAttributes';
import { useConfirm } from '@/composition/confirm';

interface EventContentArgElement extends EventContentArg {
  el: HTMLElement;
}

export default defineComponent({
  components: {
    FullCalendar,
  },
  setup(_, context) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('schedules'));
    const { confirmDialog } = useConfirm();

    const isLoading = ref(false);
    const isPc = ref(window.matchMedia('(min-width: 600px)').matches);
    onMounted(() => {
      window.addEventListener('resize', () => {
        isPc.value = window.matchMedia('(min-width: 600px)').matches;
      });
    });
    const calendarOptions = computed(() => {
      return {
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,listMonth',
        },
        buttonText: {
          month: 'カレンダー',
          list: 'リスト',
        },
        contentHeight: 'auto',
        dayMaxEventRows: isPc.value ? false : 0,
        views: {
          dayGridMonth: {
            moreLinkContent: {
              html: '<div class="text-center"><span class="fc-list-event-dot"></span></div>',
            },
            dayCellClassNames: 'style-daycell',
          },
          listMonth: {
            eventDidMount: (info: EventContentArgElement) => {
              if (info.event._def.extendedProps.isDraft) {
                info.el.classList.add('is-draft');
              }
            },
          },
        },
        eventTimeFormat: {
          hour: 'numeric',
          minute: '2-digit',
          meridiem: false,
        },
        plugins: [dayGridPlugin, listPlugin, interactionPlugin],
        events: async (info: EventSourceFuncArg) => {
          return await getScheduleItems(info.start.getTime(), info.end.getTime());
        },
        locales: allLocales,
        locale: 'ja',
        selectable: myRoleSettings.value ? myRoleSettings.value.createAndUpdate : false,
        selectLongPressDelay: 300,
        select: async (info: DateSelectArg) => {
          if (!(await confirmDialog('指定された期間でスケジュールを作成します。\n作成ページへ移動しますか？'))) return;
          context.root.$router.push({
            path: '/schedule/create',
            query: { start: String(info.start.getTime()), end: String(info.end.getTime() - 1) },
          });
        },
        loading: (loading: boolean) => (isLoading.value = loading),
      };
    });

    return {
      isLoading,
      calendarOptions,
    };
  },
});
